import { SxProps, useTheme } from '@mui/material';
import { IconButton } from '@portals/core/src/components/IconButton/IconButton';
import type { Theme } from '@portals/core/src/themes/themes';
import React, { MouseEventHandler, useCallback } from 'react';

interface Props {
  sx?: SxProps<Theme>;
  icon: React.ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  size?: 'default' | 'small';
}

export const ActionButton: React.FunctionComponent<Props> = ({ onClick, icon, size = 'default', ...props }: Props) => {
  const theme = useTheme();
  const sx = Object.assign({}, props.sx, {
    color: 'inherit',
    borderRadius: '50%',
    fontSize:
      size === 'small'
        ? { xs: theme.spacing(5), lg: theme.spacing(6) }
        : { xs: theme.spacing(6), lg: theme.spacing(8) },
    '&.MuiIconButton-root': {
      padding: size === 'small' ? { xs: theme.spacing(4), lg: '15px' } : { xs: 3 },
    },
  });

  const onMouseUp = useCallback((e) => {
    e.currentTarget.blur();
  }, []);

  return (
    <IconButton onClick={onClick} onMouseUp={onMouseUp} sx={sx}>
      {icon}
    </IconButton>
  );
};

ActionButton.displayName = 'ActionButton';
