import { HeaderBar } from '@portals/core/src/components/HeaderBar/HeaderBar';
import { Header } from '@portals/core/src/containers/Header/Header';
import { isHeader, isHeaderBar } from '@portals/root/models';
import { AccessibilityLinks } from '@portals/root/src/components/AccessibilityLinks/AccessibilityLinks';
import { InternIconLink } from '@portals/root/src/components/InternIconLink/InternIconLink';
import { PageLinkList } from '@portals/root/src/components/PageLinkList/PageLinkList';
import { Consumer as RootConsumer, Node, SingleNode } from '@portals/root/src/consumers/Consumer/Consumer';
import { loadIcon } from '@portals/root/src/utils/loadIcon';
import React from 'react';

import { isEstateAdFlowSelector } from '../../../models/estate-ad-flow-selector';
import { isEstateList } from '../../../models/estate-list';
import { isEstateSearch } from '../../../models/estate-search';
import { isLinkIndexList } from '../../../models/link-index-list';
import { EstateAdFlowSelector } from '../../components/EstateAdFlowSelector/EstateAdFlowSelector';
import { EstateListWrapper } from '../../components/EstateList/EstateListWrapper';
import { EstateSearchWrapper } from '../../components/EstateSearch/EstateSearchWrapper';
import { renderBookmarkButton, renderEstateLink } from '../../components/ListView/ListView.utils';
import { LoginButton } from '../../components/LoginButton/LoginButton';
import { createTheme as createHeaderTheme } from '../../themes/immobilien/header';

export class Consumer extends RootConsumer {
  protected renderNode(node: SingleNode): React.ReactNode {
    const Consumer = this.constructor;
    if (isEstateSearch(node)) {
      const numberPerimeter = Number(node.perimeter);
      const perimeter = !isNaN(numberPerimeter) ? numberPerimeter : null;
      return <EstateSearchWrapper perimeter={perimeter} />;
    }

    if (isHeader(node)) {
      return (
        <Header id={`${node.__contentType}.${node.id}`} createTheme={createHeaderTheme}>
          <Consumer node={node.content} />
        </Header>
      );
    }

    if (isHeaderBar(node)) {
      const MobileIcon = loadIcon(process.env.TENANT_ICON);
      return (
        <HeaderBar
          id={`${node.__component}.${node.id}`}
          level1Navigation={node.level1Navigation?.items?.length > 0 ? <Consumer node={node.level1Navigation} /> : null}
          level2Navigation={node.level2Navigation?.items?.length > 0 ? <Consumer node={node.level2Navigation} /> : null}
          level3Navigation={node.level3Navigation?.items?.length > 0 ? <Consumer node={node.level3Navigation} /> : null}
          accessibilityLinks={
            node.accessibilityLinks?.items?.length > 0 ? (
              <AccessibilityLinks
                node={node.accessibilityLinks}
                renderNode={this.renderAccessibilityLinkNode() as (node: Node) => React.ReactElement}
              />
            ) : null
          }
          searchButton={
            node.searchButton?.link.length > 0 ? <InternIconLink key={node.id} node={node.searchButton} /> : null
          }
          button={
            <>
              <LoginButton />
              {node.button && <Consumer node={node.button} />}
            </>
          }
          menuButton={
            <>
              <LoginButton />
              {node.menuButton && <InternIconLink key={node.id} node={node.menuButton} />}
            </>
          }
          mobileIcon={<MobileIcon data-testid="header-bar-logo" />}
        />
      );
    }

    if (isEstateList(node)) {
      return (
        <EstateListWrapper
          estateListHeadline={node?.headline?.text}
          estateListHeadlineVariant={node?.headline?.component}
          estateSearchParameters={node?.estateSearchParameters}
          objectTypes={node?.objectTypes}
          renderBookmarkButton={renderBookmarkButton}
          renderEstateLink={renderEstateLink}
          estatesData={node?.estatesData}
        />
      );
    }

    if (isEstateAdFlowSelector(node)) {
      return <EstateAdFlowSelector />;
    }

    if (isLinkIndexList(node)) {
      return <PageLinkList linkedPages={node.linked_pages} />;
    }

    return super.renderNode(node);
  }

  private renderAccessibilityLinkNode() {
    return (node: Node) => this.renderNode(node as SingleNode);
  }
}
