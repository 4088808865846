export interface LinkIndexList {
  __contentType: 'link-index-list';
  id: string;
  name: string;
  linked_pages: [];
}

export function isLinkIndexList(obj: { __contentType?: string }): obj is LinkIndexList {
  return obj.__contentType === 'link-index-list';
}
