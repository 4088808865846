/**
 * Model definition for Meta
 */

export interface FormsEstateSearch {
  __component: 'forms.estate-search';
  perimeter?: string;
}

export function isEstateSearch(obj: { __component?: string }): obj is FormsEstateSearch {
  return obj.__component === 'forms.estate-search';
}
