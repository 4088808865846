import type { Theme } from '@portals/core/src/themes/themes';

import { createTheme as createBaseTheme } from './main';

const options = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 1080,
      md: 1080,
      lg: 1440,
    },
  },
  palette: {
    action: {
      active: '#FFFFFF',
      hover: '#B60000',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#000000',
    },
    background: {
      default: '#EE0000',
    },
  },
};

export function createTheme(): Theme {
  return createBaseTheme(options);
}

export const theme: Theme = createTheme();
