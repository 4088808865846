import { EstateList, type EstateListProps } from '@portals/core-immobilien/src/components';
import React from 'react';

import { useNoResultActionUtils } from './useNoResultActionUtils/useNoResultActionUtils';

export const EstateListWrapper: React.FC<EstateListProps> = (props): React.ReactElement => {
  const { isProcessing, handleAction } = useNoResultActionUtils();
  return <EstateList {...props} useNoResultActionUtils={{ isProcessing, handleAction }} />;
};

EstateListWrapper.displayName = 'EstateListWrapper';
