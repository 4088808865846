import { EstateSearchProps } from '@portals/sip-client-data/src/general/ApiClientTypes';
import { useRouter } from 'next/router';
import { stringifyUrl } from 'query-string';

export const useEstateSearchHandler = () => {
  const router = useRouter();

  const handleEstateSearch = (params: EstateSearchProps) => {
    const url = stringifyUrl({ url: '/immobilien/treffer', query: params });
    router.push(url);
  };

  return { handleEstateSearch };
};
