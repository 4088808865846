import { useContext } from 'react';

import { UserContext } from '../../components/LoginHandler/LoginHandler';
import { BookmarkContext } from '../../contexts/BookmarkProvider';
import { loginConfig } from '../../contexts/LoginContext';
import { addBookmark, isLocalStorageBookmarksEnabled, removeBookmark } from '../../utils/bookmarks';
import { addBookmark as _legacy_addBookmark, removeBookmark as _legacy_removeBookmark } from '../../utils/userApi';
import { QUERY_ADD_BOOKMARK_ID } from '../useInitialUserActionRunner/useInitialUserActionRunner';
import { useIsLoggedIn } from '../useIsLoggedIn/useIsLoggedIn';

export type UseIsBookmarkedResult = {
  isBookmarked: boolean;
  toggleBookmark: (estateId?: string | Event) => void;
};

export const useIsBookmarked = (estateID: string): UseIsBookmarkedResult => {
  const [isloggedIn] = useIsLoggedIn();
  const { bookmarks: _legacy_bookmarks, loadBookmarks: _legacy_loadBookmarks, doLogin } = useContext(UserContext);
  const { bookmarks, loadBookmarks } = useContext(BookmarkContext);
  const isBookmarked =
    (!isLocalStorageBookmarksEnabled() && isloggedIn && !!estateID && _legacy_bookmarks?.indexOf(estateID) >= 0) ||
    (isLocalStorageBookmarksEnabled() && bookmarks.indexOf(estateID) >= 0);

  const handleBookmark = async (givenEstateId: string) => {
    if (!isLocalStorageBookmarksEnabled()) {
      if (!isBookmarked) {
        await _legacy_addBookmark(loginConfig.userApiUrl, givenEstateId);
      } else {
        await _legacy_removeBookmark(loginConfig.userApiUrl, givenEstateId);
      }
      await _legacy_loadBookmarks();
    } else {
      if (!isBookmarked) {
        addBookmark(givenEstateId);
      } else {
        removeBookmark(givenEstateId);
      }
      loadBookmarks();
    }
  };
  const bookmarkItem = async (givenEstateId?: string | Event) => {
    if (!isloggedIn && !isLocalStorageBookmarksEnabled()) {
      const idToBookmark = typeof givenEstateId === 'string' ? givenEstateId : estateID;
      doLogin(
        () => {
          handleBookmark(idToBookmark);
        },
        { [QUERY_ADD_BOOKMARK_ID]: idToBookmark }
      );
    } else {
      await handleBookmark(typeof givenEstateId === 'string' ? givenEstateId : estateID);
    }
  };

  return { isBookmarked, toggleBookmark: bookmarkItem };
};
